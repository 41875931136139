import  { SMS_TYPES } from '../../types'
import {orderService} from "../../services/orderService";
import { Notification } from "element-react";
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

class SmsActions {
    constructor(_props) {
        this.api = config['SMS_API']
        this.rest  = '/'
        this.arcive = 'b/'
    }


    sendSms = (jwt, body, history, historyPush) => {

        return async dispatch => {

            try {

                dispatch({
                    type: SMS_TYPES.SEND_SMS_P
                })

                const { data } = await axios.post(
                    this.api + 'bulk/telnyx/sms/single',
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'sms succesfuly send',
                }, 20)

                dispatch({
                    type: SMS_TYPES.SEND_SMS_F,
                    payload: data
                })

                if (historyPush) {
                    history.push('/sms/history')
                }

                return data

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 

                dispatch({
                    type: SMS_TYPES.SEND_SMS_R,
                    payload: e
                })

                return e.response
            
            }
        }
    }


    sendMms = (jwt, body, history, historyPush) => {

        return async dispatch => {

            try {

                dispatch({
                    type: SMS_TYPES.SEND_SMS_P
                })

                const { data } = await axios.post(
                    this.api + 'bulk/telnyx/mms',
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Message',
                    message: 'message succesfuly send',
                }, 20)

                dispatch({
                    type: SMS_TYPES.SEND_SMS_F,
                    payload: data
                })

                if (historyPush) {
                    history.push('/sms/history')
                }

                return data

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 
                dispatch({
                    type: SMS_TYPES.SEND_SMS_R,
                    payload: e
                })

                return e.response
            
            }
        }
    }

    sendMmsTwilio = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: SMS_TYPES.SEND_SMS_P
                })

                const { data } = await axios.post(
                    this.api + 'bulk/twilio/mms',
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Mms',
                    message: 'mms succesfuly send',
                }, 20)

                dispatch({
                    type: SMS_TYPES.SEND_SMS_F,
                    payload: data
                })

                history.push('/sms/history')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 
                dispatch({
                    type: SMS_TYPES.SEND_SMS_R,
                    payload: e
                })
            
            }
        }
    }

    sendSmsTwilio = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: SMS_TYPES.SEND_SMS_P
                })

                const { data } = await axios.post(
                    this.api + 'bulk/twilio/sms/single',
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'sms succesfuly send',
                }, 20)

                dispatch({
                    type: SMS_TYPES.SEND_SMS_F,
                    payload: data
                })

                history.push('/sms/history')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 
                dispatch({
                    type: SMS_TYPES.SEND_SMS_R,
                    payload: e
                })
            
            }
        }
    }

    sendSmsMobiniti = (jwt, body, history) => {

        return async dispatch => {

            try {

                dispatch({
                    type: SMS_TYPES.SEND_SMS_P
                })

                const { data } = await axios.post(
                    this.api + '/mobiniti/sms/send',
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                Notification.success({
                    title: 'Sms',
                    message: 'sms succesfuly send',
                }, 20)

                dispatch({
                    type: SMS_TYPES.SEND_SMS_F,
                    payload: data
                })

                history.push('/call/sms')

            } catch (e) {

                if (e.response.status === 422) {
                    if (Array.isArray(e.response.data)) {
                        e.response.data.forEach(err => {
                            Notification.error({
                                title: err.path,
                                message: err.msg
                            }, 20)
                        })
                    } else {
                        Notification.error({
                            title: e.response.data.path,
                            message: e.response.data.msg
                        }, 20)
                    }
                } 
                dispatch({
                    type: SMS_TYPES.SEND_SMS_R,
                    payload: e
                })
            
            }
        }
    }

    pagination = (jwt, params, page, count, query) => {
        return async dispatch => {
            try {

                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + `${params}` + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                if (data.data.length) {
                    data.data = data.data.map(({ status: deliveryStatus, ...rest }) => ({ deliveryStatus, ...rest }))
                }

                dispatch({
                    type: SMS_TYPES.SMS_PAG_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.SMS_PAG_R,
                    payload: e
                })
            }
        }
    }

    paginationMessages = (jwt, to, page, count) => {
        return async dispatch => {
            try {


                dispatch({
                    type: SMS_TYPES.SMS_MESSAGE_PAG_P
                })

                const { data } = await axios.get(
                    `${this.api}bulk/message/${to}/${page}/${count}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.SMS_MESSAGE_PAG_F,
                    payload: data
                })

                return data

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.SMS_MESSAGE_PAG_R,
                    payload: e
                })
                return e.response
            }
        }
    }

    numberList = (jwt) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_P
                })

                const { data } = await axios.get(
                    this.api + `telnyx/number/list`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_R,
                    payload: e
                })
            }
        }
    }

    numberBuy = (jwt, body) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_P
                })

                const { data } = await axios.post(
                    this.api + 'telnyx/number/buy',
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_R,
                    payload: e
                })
            }
        }
    }


    numberListTwilio = (jwt) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_P
                })

                const { data } = await axios.get(
                    this.api + `twilio/number/list`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_LIST_R,
                    payload: e
                })
            }
        }
    }

    numberBuyTwilio = (jwt, body) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_P
                })

                const { data } = await axios.post(
                    this.api + 'twilio/number/buy',
                    body,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.TELNYX_NUMBER_BUY_R,
                    payload: e
                })
            }
        }
    }

    seeAllPhones = (jwt, page, count, query, search) => {

        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES.FETCH_SMS_PHONES_P
                })
                
                const searchString = qs.stringify(query)

                const { data } = await axios.get(
                    this.api + 'phones/' + page + '/' + count + '?' + searchString,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

            dispatch({
                type: SMS_TYPES.FETCH_SMS_PHONES_F,
                payload: data,
                search: search
            })

            } catch (e) {

                dispatch({
                    type: SMS_TYPES.FETCH_SMS_PHONES_R,
                    payload: e
                })
            
            }
        }
    }

    newSms = () => {
        return dispatch => {
            return dispatch({
                type: SMS_TYPES.NEW_SMS
            })
        }
    };

    getBalanceTelnyx = (jwt) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + 'balance/telnyx',
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.GET_BALANCE_TELNYX_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.GET_BALANCE_TELNYX_R,
                    payload: e
                })
            }
        }
    }

    getBalanceTwilio = (jwt) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + 'balance/twilio',
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.GET_BALANCE_TWILIO_F,
                    payload: data
                })

            } catch (e) {

                dispatch({
                    type: SMS_TYPES.GET_BALANCE_TWILIO_R,
                    payload: e
                })
            }
        }
    }

    getSendPulseBalance = (jwt) => {
        return async dispatch => {
            try {
                const { data } = await axios.get(
                this.api + 'send_pulse/sms/balance/usd',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.SEND_PULSE_SMS_BALANCE_F,
                    payload: data
                })

            } catch (e) {
                dispatch({
                    type: SMS_TYPES.SEND_PULSE_SMS_BALANCE_R,
                    payload: e
                })
            }
        }
    }

    update = (jwt, body, Type, history) => {
        return async dispatch => {
            try {
                await axios.put(
                    this.api+ `/${body.id}`,
                    { 
                        status: body.status, 
                    },
                    {
                        headers: 
                        {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES[Type + '_UPDATE_F'],
                    payload: body
                })

                
                history.goBack()
                
            } catch (e) {

                dispatch({
                    type: SMS_TYPES[Type + '_UPDATE_R'],
                    payload: e
                })

            }
        }
    }

    destroy = (jwt, id, Type) => {
        return async dispatch => {
            try {
                await axios.delete(
                    this.api + `/${id}`,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
                dispatch({
                    type: SMS_TYPES[Type + '_DELETE_F'],
                    payload: id
                })
            } catch (e) {
                dispatch({
                    type: SMS_TYPES[Type + '_DELETE_R'],
                    payload: e
                })
            }
        }
    }

    show = (jwt, id, Type) => {
        return async dispatch => {
            try {

                dispatch({
                    type: SMS_TYPES[Type + '_GET_ONE_P']
                })

                const response = await axios.get(
                    this.api + `${id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES[Type + '_GET_ONE_F'],
                    payload: response.data
                })

            } catch (e) {

                dispatch({
                    type: SMS_TYPES[Type + '_GET_ONE_R'],
                    payload: e
                })

            }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
    */
    bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                this.api + this.arcive + params,
                ids,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.SMS_BULK_ARCIVE_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: SMS_TYPES.SMS_BULK_ARCIVE_R,
                    payload: e
                })

            }
        }
    }

    /**
        * @name bulk
        * @param elem
        * @returns {function(*)}
    */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
        return async dispatch => {
            try {
                await axios.post(
                    this.api + 'b/d',
                    ids,
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES.SMS_BULK_DESTROY_F,
                    payload: ids
                })

                dispatch(this.pagination(...otherDispatchParams))
                
            } catch (e) {

                dispatch({
                    type: SMS_TYPES.SMS_BULK_DESTROY_R,
                    payload: e
                })

            }
        }
    }

    create = (jwt, body, Type) => {
        return async dispatch => {
            try {
                const response = await axios.post(
                    this.api,
                    body,
                    {
                        headers: 
                        {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )

                dispatch({
                    type: SMS_TYPES[Type + '_CREATE_F'],
                    payload: body
                })

                return response

            } catch (e) {

                dispatch({
                    type: SMS_TYPES[Type + '_CREATE_R'],
                    payload: e
                })
            
                return e.response

            }
        }
    }

    getOrder = (jwt, page, count, filter, Type) => {
        return async dispatch => {
            return orderService.getPaginationPg(jwt, page, count, filter).then(orders => {
                return dispatch({
                    type: SMS_TYPES[Type + '_GET_ORDER'],
                    payload : orders
                })
            })  
        }
    }

    update = (data) => {
        return async dispatch => {
            dispatch({
                type: SMS_TYPES.UPDATE_SMS,
                payload: data
            })
        }
    }

    getStatistics = (jwt, query) => {
        return async dispatch => {
            try {
    
                const searchString = qs.stringify(query)
    
                dispatch({
                    type: SMS_TYPES.SMS_STATISTICS_P
                })
    
                const response = await axios.get(`${this.api + `${this.rest}s?${searchString}`}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${jwt}`
                        }
                    }
                )
    
                dispatch({
                    type: SMS_TYPES.SMS_STATISTICS_F,
                    payload: response.data
                })
    
            } catch (e) {

                dispatch({
                    type: SMS_TYPES.SMS_STATISTICS_R,
                    payload: e
                })

            }
        }
    };

}




export default new SmsActions()