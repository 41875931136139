import config from '../../../config/app'
import {Service} from "./Service";
import qs from 'qs';

const rest = 's';
const all = 's/r';
const allR = 's/d';
const done = 'done';
const sales = 'sales/l'
const categories = 'sales/categories'
const types = 'sales/types'
const groupe = 's/sales/group';
const saleStatus = '/sales/status/l'
const cancellationReasons = '/sales/cancellation_reasons/l'


class OrderService extends Service {
  constructor(api, all, allR, rest, done) {
    super(api, all, allR, rest);
    this.done = done;
    this.groupe = groupe
    this.saleStatus = saleStatus
    this.cancellationReasons = cancellationReasons
    this.sales = sales
    this.categories = categories
    this.types = types
  }
  
  getListDone(jwt) {
    const url = `${this.api}${this.rest}/${this.done}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  changeStatus(id, data, jwt) {
    const url = `${this.api}s/group/u/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  changeFranchise(id, data, jwt) {
    const url = `${this.api}s/group/u/f/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  changePaymentMethod(id, data, jwt) {
    const url = `${this.api}s/group/u/p/${id}`;
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  statistics( jwt, query) {
    const searchString = qs.stringify(query);
    const url = `${this.api}s/sales/all/statistics?${searchString}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getLocations( jwt, query) {
    const searchString = qs.stringify(query);
    const url = `${this.api}s/sales/all/locations?${searchString}`;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  changeOrderGroupListsDone(array) {

    let requestsArr = array.map(elem => {
      elem.done = true;
      return this.edit(elem, elem._id)
    });
    return Promise.all(requestsArr).then(data => {
      return data;
    })
  }

  getPagination(jwt, page, count, status) {
    const orderGroup =`${this.groupe}/${page}/${count}`
    const url = new URL(orderGroup, this.api);
    if (status) {
        url.searchParams.set('fields[status]', status);
    }
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${jwt}`,
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getPaginationPg(jwt, page, count, query) {
    const searchString = qs.stringify(query);
    const url =`${this.api}${this.rest}/${this.sales}/${page}/${count}?${searchString}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache',
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getCategories(jwt, page, count) {
    const url =`${this.api}${this.rest}/${this.categories}/${page}/${count}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getTypes(jwt, page, count) {
    const url =`${this.api}${this.rest}/${this.types}/${page}/${count}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getAllOrderStatus(jwt) {
    const orderStatus =`${this.rest}${this.saleStatus}`
    const url = new URL(orderStatus, this.api);
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  getAllOrderCancellationReasons(jwt) {
    const orderStatus =`${this.rest}${this.cancellationReasons}`
    const url = new URL(orderStatus, this.api);
    return fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    }).then(response => {
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      } else {
        return response.json()
      }
    })
  }

  async destroy(jwt, ids) {
    const orderStatus =`${this.rest}/b/d`
    const url = new URL(orderStatus, this.api);
    const res = await fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
    return res.json()
  }

  async archive(jwt, ids) {
    const orderStatus =`${this.rest}/b/a`
    const url = new URL(orderStatus, this.api);
    const res = await fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
    return res.json()
  }

  async recover(jwt, ids) {
    const orderStatus =`${this.rest}/b/r`
    const url = new URL(orderStatus, this.api);
    const res = await fetch(url, {
      method: 'POST',
      body:  JSON.stringify(ids),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
    return res.json()
  }

  async getOrder(jwt, id) {
    const url = new URL(`${this.rest}/${id}`, this.api);
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${jwt}`
      }
    })
    return res.json()
  }


  async listOrderProducts(id) {
    const url = new URL(`${this.rest}/sales/p/${id}`, this.api);
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      }
    })
    return res.json()
  } 
  
}

const orderService = new OrderService(config['ORDER'], groupe, allR, rest, done);


export {orderService}