import React, { Fragment } from "react";
import moment from 'moment';
import './DatePicker.css'


const DatePicker = (props) => {
  const { value, handleChange } = props

  return (
    <div className="datepicker-wrapper">
        <label className="datepicker-label" htmlFor="startDate">Start Date
          <input
              id="startDate"
              type="datetime-local"
              value={moment(new Date(value.startDate)).format("YYYY-MM-DDTHH:mm:ss")}
              max={value.endDate}
              autoComplete="off"
              onChange={e => handleChange(e, 'startDate')}
          />
        </label>
        <label 
          className="datepicker-label" 
          htmlFor="endDate"
          style={
            !value.startDate ? 
            {
              color: "rgb(236 236 236)",
              border: "1px solid rgb(236 236 236)"
            }
            :
            {

            } 
          }
        >
          End Date
        <input
            id="endDate"
            style={
              !value.startDate ? 
              {
                color: "rgb(236 236 236)"
              }
              :
              {
  
              } 
            }
            type="datetime-local"
            value={moment(new Date(value.endDate)).format("YYYY-MM-DDTHH:mm:ss")}
            min={value.startDate}
            autoComplete="off"
            onChange={e => handleChange(e, 'endDate')}
          />
        </label>
    </div>
  );
};

export default DatePicker