import React, { useState } from "react";
import classnames from 'classnames';
import { dialUpdate } from '../../services/callService';
import Draggable from "react-draggable";
import { useSelector } from 'react-redux';
import './Incoming.css';

function parseParams(paramString) {
    const params = {};
    const paramPairs = paramString.split('&');

    paramPairs.forEach(pair => {
        const [key, value] = pair.split('=');
        params[key] = decodeURIComponent(value.replace(/\+/g, ' '));
    });

    return params;
}

const Incoming = ({ connection, children }) => {
    const [muted, setMuted] = useState(false);
    const getButtonClass = (icon, enabled) => classnames(`btn-action fa ${icon}`, { disable: !enabled });
    const { account } = useSelector((state) => state);
    const parsedParams = parseParams(connection.parameters.Params);
    const storeName = parsedParams['storeName'];
    const CallSid = parsedParams['CallSid1'];

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleDrag = (e, ui) => {
        const { x, y } = position;
        setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    };

    const acceptConnection = () => {
        connection.accept();
        dialUpdate(account, CallSid, {});
    };
  
    const rejectConnection = () => {
        connection.reject();
        dialUpdate(account, CallSid, {});
    };

    const hungupConnection = () => {
        connection.disconnect();
        dialUpdate(account, CallSid, {});
    };

    const mute = () => {
        setMuted(!muted);
        connection.mute(!muted);
    };

  return (
        <Draggable
            position={position}
            onDrag={handleDrag}
            cancel=".incomingFooter"
        >
            <div className="incomingCard">
                
                <div className="incomingHeader">
                    <p className="phoneNumber">{connection.parameters.From}</p> 
                    <p className="calling">
                        Calling {storeName}
                    </p>
                </div>
                {
                    connection._isAnswered &&
                    <>
                        {
                            children
                        }
                    </>
                }
                <div className="incomingFooter">
                    {
                        !connection._isAnswered 
                        ?
                        <div className="outboundFooter">
                            <button
                                className="btn-action hangup fa fa-phone"
                                onClick={rejectConnection}
                            />
                            <button
                                className="btn-action fa-call fa fa-phone"
                                onClick={acceptConnection}
                            />
                        </div>
                        :
                        <div className="outboundFooter">
                            <button
                                key="btnAudio"
                                className={getButtonClass('fa-microphone', !muted)}
                                onClick={() => mute()}
                            />
                            <button
                                className="btn-action hangup fa fa-phone"
                                onClick={() => hungupConnection()}
                            />
                        </div>
                    }
                </div>
            </div>
        </Draggable>
    );
    };

export default Incoming;
