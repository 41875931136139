import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    Card, CardBody,
    CardHeader, Col,
    Button, Badge,
    Collapse
} from 'reactstrap';
import { MessageBox } from 'element-react'
import config from "../../../config/app";
import momentTz from 'moment-timezone';
import 'react-select/dist/react-select.css';
import { orderActions } from "../../redux-modules/actions";
import Dropdown from './DropDown';
import { updateDimensions } from '../../redux-modules/services/mobileService';
import { Link } from 'react-router-dom';
import { showCancelOrderDialog, showStaffDialog } from '../../components/modal/Utils';
import { formatUSNumber } from '../../helpers/index';
import { Notification } from "element-react";
const isMobile = updateDimensions();

const callAllowedStatus = ['accepted', 'progress', 'delivering', 'cancel', 'done'];

const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone
const getFormattedDateTimeWithTZ = (date) => {
    return momentTz((date)).tz(timeZoneString).format('MMMM Do YYYY, h:mm:ss a')
}

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: null,
            done: 'null',
            /*---Pagination values---*/
            showEntries: 500,
            pagination: 0,
            selectedPagination: 1,
            isSmallScreen: window.innerWidth < 1800
        }
        this._changeOrderGroupListsDone = this._changeOrderGroupListsDone.bind(this)
        this._changeOrderGroupFranchise = this._changeOrderGroupFranchise.bind(this)
        this._changeOrderPaymentMethod = this._changeOrderPaymentMethod.bind(this)
        this.handleResize = this.handleResize.bind(this);
    }


    _toggle(elem) {
        this.setState({
            collapse: !this.state.collapse
        })
        if (!elem.orderProduct.length) {
            const { listOrderProducts } = this.props;
            listOrderProducts(elem.orderId);
        }
    }

    _changeOrderGroupListsDone(elem, status, prevStatus) {
        const { changeStatus, chooseStaff, showDialog, statusTab, account, showOrderStaffDialog } = this.props
        const data = {
            status: status,
            prevStatus: prevStatus
        };
        if (status !== 'not verified') {
            if (status !== 'cancel' && status !== 'delivering') {
                changeStatus(elem.mongoId, data, account.token, statusTab)
                if (status === 'accepted') {
                    chooseStaff(account.token, {
                        orderId: elem.orderId,
                        status: status
                    })
                }
            } else if (status === 'cancel') {
                showDialog(elem.mongoId, status)
            } else if (status === 'delivering') {
                showOrderStaffDialog(elem, status)
            }
        }
    }

    _changeOrderGroupFranchise(elem, value, label) {
        const { changeFranchise } = this.props
        const data = {
            Id: elem.mongoId,
            franchiseId: value,
            name: label
        };
        changeFranchise(elem.mongoId, data, this.props.account.token, this.props.statusTab)
    }

    _changeOrderPaymentMethod(elem, value) {
        const { changePaymentMethod } = this.props
        const data = {
            Id: elem.mongoId,
            paymentMethod: value,
        };
        changePaymentMethod(elem.mongoId, data, this.props.account.token, this.props.statusTab)
    }

    archive(elem) {
        const { archiveOrder, pageOrders, orderStatistics,  account, filter, dataFilter, statusTab, page, showEntries } = this.props
        const key = 'status'
        archiveOrder(account.token, elem)
        .then((data) => {
            if (data.success) {
                orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: false} })
                pageOrders(account.token, page, showEntries, { ...filter ,...dataFilter, ...{ [key]: statusTab === 'all' ? null : statusTab, deleted: false} })
            }
        }) 
    }

    recover(elem) {
        const { recoverOrder, pageOrders, orderStatistics, account, filter, dataFilter, statusTab, page, showEntries } = this.props
        recoverOrder(account.token, elem)
        .then((data) => {
            if (data.success) {
                orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: true} })
                pageOrders(account.token, page, showEntries, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: true} })
            }
        })
    }

    destroy(elem) {
        const { destroyOrder, pageOrders, orderStatistics,  account, filter, dataFilter, statusTab, page, showEntries } = this.props
        const key = 'status'
        MessageBox.msgbox({
          title: 'Message!',
          message: 'Are you sure you want to delete it?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then(action => {
          if (action == 'confirm') {
            destroyOrder(account.token, elem)
            .then((data) => {
                if (data.success) {
                    orderStatistics(account.token, { ...filter ,...dataFilter, ...{ ['status']: statusTab === 'all' ? null : statusTab, deleted: true} })
                    pageOrders(account.token, page, showEntries, { ...filter ,...dataFilter, ...{ [key]: statusTab === 'all' ? null : statusTab, deleted: true} })
                }
            })
          }
        })
    }

    financial(x) {
        const value =  Number.parseFloat(x).toFixed(1);
        return Math.round(value*100)/100
    }

    handleClick(elem) {
        if (elem.franchise) {
            const origin = elem.franchise.address.address
            const destination = elem.address.address
            const originEncoded = encodeURIComponent(origin);
            const destinationEncoded = encodeURIComponent(destination);
            const url = `https://www.google.com/maps/dir/?api=1&origin=${originEncoded}&destination=${destinationEncoded}`;
            window.open(url, '_blank');
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
      }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({
          isSmallScreen: window.innerWidth < 1800
        });
    }


    copyText(text) {
        const tempInput = document.createElement('input');
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        Notification.success({
            title: 'success',
            message: 'succesfuly copy',
        }, 20)
    }

    /*---crud---*/
    render() {
        const { elem, filter, dataFilter, statusList, access } = this.props;
        const { collapse, isSmallScreen } = this.state

        return (<div>
            { 
                (!isMobile && !isSmallScreen) ?
                <CardBody className="orders-body">
                    <Col col="4" xs="4" sm="3" md="2" lg="1" className="px-2">
                        <p>
                            {
                                elem.shippingCode
                            }
                        </p>
                        <p>
                            {
                                elem.orderType && elem.orderType.name
                            }
                        </p>
                    </Col>
                    <Col col="8" xs="8" sm="4" md="3" lg="1" className="px-2">
                        <Dropdown
                            _changeOrderGroupListsDone = {(v1, v2) => this._changeOrderGroupListsDone(v1, v2, elem.status)}
                            list={ statusList } 
                            elem = { elem } 
                        />
                        {
                            (elem.status == 'cancel') &&
                            <>
                                {
                                    elem.orderCancellationReason &&
                                    <><span><strong>cancellation reason:</strong> {elem.orderCancellationReason.name}</span><br /></>
                                }
                                <span><strong>message:</strong> {elem.message}</span> 
                            </>  
                        }

                        {
                            elem.orderStaff && elem.orderStaff.map((item, i) =>
                                <p key={i}>
                                    {
                                        item.status !== 'delivering' ?
                                        <span><strong>manager: </strong>{item.user.username}</span> :
                                        <span><strong>driver: </strong>{item.user.username}</span>
                                    }
                                </p>  
                            )
                        }
                    
                    </Col>
                    <Col col="4" xs="4" sm="3" md="2" lg="1" className="px-2">
                        {
                            elem.discountPercent && parseFloat(elem.discountPercent) 
                            ?
                            <>{this.financial(elem.total - (elem.total * parseFloat(elem.discountPercent) / 100)) + " " + elem.currencyCode}</>
                            :
                            <>{Number(elem.total) + " " + elem.currencyCode}</>
                        }
                        {
                            !!(elem.discountPercent && parseFloat(elem.discountPercent)) &&
                            <div id="discount-display">{`${parseFloat(elem.discountPercent)}% off`}</div>
                        }
                        {
                            elem.promoCodeId &&
                            <div id="discount-display">{`Promo`}</div>
                        }
                    </Col>
                    <Col col="4" xs="4" sm="3" md="2" lg="1" className="px-2">
                        {
                            elem.discountPercent && parseFloat(elem.discountPercent) ?
                            <>
                                <div class="price-style-1">
                                    <del>
                                        <span class="amount">{Number.parseFloat(Number(elem.total) + Number(elem.tax) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode}</span>
                                    </del>
                                </div>
                                { Number.parseFloat(Number(elem.total - (elem.total * parseFloat(elem.discountPercent) / 100)) + Number(elem.tax - (elem.tax * parseFloat(elem.discountPercent) / 100)) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode }
                            </>
                            :
                            <>{ Number.parseFloat(Number(elem.total) + Number(elem.tax) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode }</>  
                        }
                        <br/> 
                        {!!elem.store && elem.store.name} 
                        <br/> 
                        {
                            !!this.props.franchiseSelect.length &&
                            <Dropdown
                                _changeOrderGroupListsDone = {this._changeOrderGroupFranchise}
                                list={this.props.franchiseSelect.map((franchise) => ({label: franchise.name, value: franchise.franchiseId}))} 
                                elem = { { 
                                    mongoId: elem.mongoId,
                                    label: elem.franchise ? elem.franchise.name : null,
                                    status: elem.franchise ? elem.franchise.franchiseId: null
                                } } 
                            />
                        }
                    </Col>
                    <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">
                        <Link to={`/user/customer/update/${elem.customer.customerId}`}>{formatUSNumber(elem.telephone)}</Link>
                        <br/>
                        <span>
                            { elem.customer.firstname && elem.customer.firstname }
                            { elem.customer.lastname && ' ' + elem.customer.lastname }
                        </span>
                        <div
                            style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}
                        >
                            {
                                elem.call && callAllowedStatus.includes(elem.status) &&
                                <Link to={`/call/tickets/${elem.mongoId}?order=${true}`}>
                                    <div 
                                        style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px"><g data-name="Layer 23"><path d="M25.08594,30a3.07625,3.07625,0,0,1-.36621-.02148A26.417,26.417,0,0,1,9.75342,23.21l-.936-.93652A26.43954,26.43954,0,0,1,2.022,7.28125,3.16134,3.16134,0,0,1,4.0249,3.96973l4.666-1.81152a2.34771,2.34771,0,0,1,2.90674,1.06641L14.87793,9.248a2.3572,2.3572,0,0,1-.4165,2.79395l-2.5752,2.52832a29.62931,29.62931,0,0,0,2.57568,3.14551,26.60062,26.60062,0,0,0,2.96582,2.40234L19.958,17.54a2.35255,2.35255,0,0,1,2.7915-.418l6.02686,3.28125a2.34994,2.34994,0,0,1,1.06592,2.9043l-1.8125,4.66895A3.17841,3.17841,0,0,1,25.08594,30ZM11.1582,21.78613a24.41125,24.41125,0,0,0,13.79395,6.20605,1.18232,1.18232,0,0,0,1.21289-.73828l1.8125-4.66895a.34841.34841,0,0,0-.15674-.4248l-6.02539-3.28125a.34977.34977,0,0,0-.41016.0625L18.4458,21.93555a1.30073,1.30073,0,0,1-1.6543.17285,29.22265,29.22265,0,0,1-3.74316-2.97852,31.855,31.855,0,0,1-3.146-3.90918,1.30372,1.30372,0,0,1,.16406-1.667l2.99365-2.93945a.34911.34911,0,0,0,.06055-.41016L9.84082,4.18066a.34965.34965,0,0,0-.4248-.1582L4.748,5.835A1.161,1.161,0,0,0,4.0083,7.04883,24.41022,24.41022,0,0,0,10.25,20.87793Zm.39551-7.69336.00146.002Z"/></g></svg>
                                    </div>
                                </Link>
                            }
                            {
                                elem.sms && callAllowedStatus.includes(elem.status) &&
                                <Link to={`/call/sms/send/${elem.mongoId}?order=${true}`}>
                                    <div 
                                        style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1" viewBox="0 0 490 490">
                                            <g>
                                                <g>
                                                    <path d="M410.103,458.74L281.861,353.511H105.453C47.313,353.511,0,305.166,0,245.755V139.016    C0,79.606,47.313,31.26,105.453,31.26h279.094C442.687,31.26,490,79.606,490,139.016v106.739    c0,49.99-33.511,92.697-79.897,104.511V458.74z M105.453,61.886c-41.257,0-74.828,34.603-74.828,77.131v106.739    c0,42.528,33.571,77.131,74.828,77.131h187.354l86.671,71.104v-70.043l13.518-1.6c37.848-4.456,66.379-37.384,66.379-76.592    V139.016c0-42.528-33.571-77.131-74.828-77.131H105.453z"/>
                                                </g>
                                                <g>
                                                    <path d="M87.284,210.524c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8c0-4.89-2.931-7.223-14.041-10.931    c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208c11.125,0,21.085,2.527,26.931,5.458l-5.264,20.486    c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806c0,4.486,3.708,6.834,15.417,10.931    c18.139,6.236,25.75,15.417,25.945,29.459c0,17.75-14.042,30.819-41.362,30.819c-12.486,0-23.612-2.722-30.819-6.624    L87.284,210.524z"/>
                                                    <path d="M172.131,171.496c0-11.888-0.389-22.042-0.778-30.431h24.973l1.361,12.875h0.583c4.097-6.041,12.486-15.013,28.875-15.013    c12.292,0,22.042,6.236,26.139,16.18h0.389c3.514-4.875,7.806-8.778,12.292-11.499c5.279-3.125,11.125-4.68,18.154-4.68    c18.333,0,32.195,12.875,32.195,41.347v56.196h-28.875V184.58c0-13.862-4.501-21.862-14.056-21.862    c-6.819,0-11.709,4.68-13.653,10.348c-0.778,2.138-1.166,5.264-1.166,7.611v55.792h-28.875V183.01    c0-12.098-4.292-20.292-13.668-20.292c-7.596,0-12.098,5.862-13.847,10.737c-0.972,2.333-1.166,5.069-1.166,7.417v55.598h-28.875    V171.496z"/>
                                                    <path d="M339.686,210.524c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8c0-4.89-2.931-7.223-14.042-10.931    c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208c11.125,0,21.07,2.527,26.931,5.458l-5.264,20.486    c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806c0,4.486,3.708,6.834,15.417,10.931    c18.139,6.236,25.75,15.417,25.945,29.459c0,17.75-14.041,30.819-41.362,30.819c-12.486,0-23.612-2.722-30.819-6.624    L339.686,210.524z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            }
                        </div>
                    </Col>
                    {
                        elem.discountPercent && parseFloat(elem.discountPercent) 
                        ?
                        <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">{this.financial(elem.tax - (this.financial(elem.tax) * parseFloat(elem.discountPercent) / 100)) + " " + elem.currencyCode}</Col>
                        :
                        <Col col="6" xs="6" sm="4" md="2" lg="1" className="px-2">{this.financial(elem.tax) + " " + elem.currencyCode}</Col>
                    }
                    <Col col="10" xs="10" sm="4" md="4" lg="2" className="px-2">
                        <button 
                            onClick={() => this.handleClick(elem)}
                            className='btn-sm btn btn-secondary'
                        >
                            Get Directions
                        </button>
                        {
                            elem.shippingAddress1 &&
                            <p style={{ marginBottom: 0 }}>
                                <span id='holdtext'>{elem.shippingAddress1}</span>
                                <button onClick={() => this.copyText(elem.shippingAddress1)} className='btn-sm btn'>
                                    <i className="fa fa-copy fa-lg mr-1"></i>
                                </button>
                            </p>
                        }
                        {
                            !!elem.address &&
                            <>
                                {
                                    elem.address.state &&
                                    <>State: {elem.address.state}<br/></>
                                }
                                {
                                    elem.address.apartment &&
                                    <>Apartment: {elem.address.apartment}<br/></>
                                }
                                {
                                    elem.address.entrance &&
                                    <>Entrance: {elem.address.entrance}<br/></>
                                }
                                {
                                    elem.address.intercom &&
                                    <>Intercom: {elem.address.intercom}</>
                                }
                            </>
                        }
                    </Col>
                    <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">
                        <Dropdown
                            _changeOrderGroupListsDone = {this._changeOrderPaymentMethod}
                            list={this.props.paymentMethods} 
                            elem = { { 
                                mongoId: elem.mongoId,
                                label: elem.paymentMethod,
                                status: elem.paymentMethod
                            } } 
                        />
                        {elem.paymentMethod == 'creditCard' && elem.paymentCustomField}
                    </Col>
                    <Col col="10" xs="10" sm="4" md="4" lg="1" className="px-2">
                        <span>{getFormattedDateTimeWithTZ(elem.dateAdded)}</span>
                        <br/>
                        {
                            !!elem.dateAdvance &&
                            <span>Order in Advance {getFormattedDateTimeWithTZ(elem.dateAdvance)}</span>
                        }
                    </Col>
                    <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">
                        <Button color="primary"
                                onClick={() => this._toggle(elem)}
                                style={{marginBottom: '1rem'}}
                        >
                                <i className="fa fa-sort-desc fa-lg "></i>
                        </Button>
                    </Col>

                    <Col col="6" xs="6" sm="3" md="2" lg="1" className="px-2">
                        {
                            !this.props.filterTab &&
                            <Link 
                                style={!access.update ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                to={`/sale/order/update/${elem.mongoId}`}
                            >
                                <Badge 
                                    color="m-1 white-space btn btn-success btn-sm"
                                    className="mr-2"
                                    style={!access.update ? { pointerEvents: 'none', opacity: '.5'} : {}}
                                >
                                    <i className="fa fa-edit fa-lg m-1  "> </i> Edit
                                </Badge>
                            </Link>
                        }
                        {
                            this.props.filterTab 
                            ?
                            <>
                                <Badge 
                                    style={!access.archive_and_recover ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                    color="m-1 white-space btn btn-warning btn-sm"
                                    onClick={() => this.recover([elem.orderId])  }
                                    className="mr-2"
                                    >
                                    <i className="fa fa-undo fa-lg m-1"> </i>
                                    Recover
                                </Badge>
                                <Badge 
                                    style={!access.delete ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                    color="m-1 white-space btn btn-danger btn-sm"
                                    onClick={() => this.destroy([elem.orderId])  }
                                    >
                                    <i className="fa fa-trash-o fa-lg m-1"> </i>
                                    Delete
                                </Badge>
                            </>
                            :
                            <Badge style={!access.archive_and_recover ? { pointerEvents: 'none', opacity: '.5'} : {}} color="m-1 white-space btn btn-primary btn-sm"
                                    onClick={() => this.archive([elem.orderId])  }
                                    >
                                <i className="fa fa-file-archive-o fa-lg m-1"></i>
                                Archive
                            </Badge>
                        }
                    </Col>

                </CardBody> 
                : 
                <section className="orderList">
                    <div className="orderList__item">
                        <div className="orderList__item__type">Order ID:</div>
                        <div className="orderList__item__value">
                            {elem.shippingCode}
                        </div>
                    </div>
                    <div className="orderList__item orderList__item--status">
                        <div className="orderList__item__type">Status:</div>
                        <div className="status-box">
                            <Dropdown
                                _changeOrderGroupListsDone = {this._changeOrderGroupListsDone}
                                list={ statusList } 
                                elem = { elem } 
                                filter = { filter }
                                dataFilter = { dataFilter }
                            />
                            {
                                (elem.status == 'cancel') &&
                                <>
                                    {
                                        elem.orderCancellationReason &&
                                        <><span><strong>cancellation reason:</strong> {elem.orderCancellationReason.name}</span><br /></>
                                    }
                                    <span><strong>message:</strong> {elem.message}</span> 
                                </>  
                            }
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Price:</div>
                        <div className="orderList__item__value">
                            {
                                elem.discountPercent && parseFloat(elem.discountPercent) 
                                ?
                                <>{this.financial(elem.total - (elem.total * parseFloat(elem.discountPercent) / 100)) + " " + elem.currencyCode}</>
                                :
                                <>{Number(elem.total) + " " + elem.currencyCode}</>
                            }
                            {
                                !!(elem.discountPercent && parseFloat(elem.discountPercent)) &&
                                <div id="discount-display">{`${parseFloat(elem.discountPercent)}% off`}</div>
                            }
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Tax:</div>
                        <div className="orderList__item__value">
                        {
                            elem.discountPercent && parseFloat(elem.discountPercent) 
                            ?
                            parseInt(elem.tax)  ? this.financial(elem.tax - (elem.tax * parseFloat(elem.discountPercent) / 100)) + elem.currencyCode : "free"
                            :
                            parseInt(elem.tax)  ? this.financial(elem.tax) + " " + elem.currencyCode : "free"
                        }
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Shipping:</div>
                        <div className="orderList__item__value">
                            { parseInt(elem.shippingPrice) ?  Math.round(elem.shippingPrice*Math.pow(10,0))/Math.pow(10,0) + elem.currencyCode : "free"}
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Total:</div>
                        <div className="orderList__item__value">
                            {
                                elem.discountPercent && parseFloat(elem.discountPercent) ?
                                <>
                                    <div class="price-style-1">
                                        <del>
                                            <span class="amount">{Number.parseFloat(Number(elem.total) + Number(elem.tax) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode}</span>
                                        </del>
                                    </div>
                                    { Number.parseFloat(Number(elem.total - (elem.total * parseFloat(elem.discountPercent) / 100)) + Number(elem.tax - (elem.tax * parseFloat(elem.discountPercent) / 100)) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode }
                                </>
                                :
                                <>{ Number.parseFloat(Number(elem.total) + Number(elem.tax) + Number(elem.shippingPrice)).toFixed(1) + " " + elem.currencyCode }</>  
                            }
                            <br/>
                            {!!elem.store && elem.store.name} 
                            <br/>
                            {
                                !!this.props.franchiseSelect.length &&
                                <Dropdown
                                    _changeOrderGroupListsDone = {this._changeOrderGroupFranchise}
                                    list={this.props.franchiseSelect.map((franchise) => ({label: franchise.name, value: franchise.franchiseId}))} 
                                    elem = { { 
                                        mongoId: elem.mongoId,
                                        label: elem.franchise ? elem.franchise.name : null,
                                        status: elem.franchise ? elem.franchise.franchiseId: null
                                    } } 
                                />
                            }
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">User:</div>
                        <div className="orderList__item__value">
                            <Link to={`/user/customer/update/${elem.customer.customerId}`}>{formatUSNumber(elem.telephone)}</Link>
                            <br/>
                            <span>
                                { elem.customer.firstname && elem.customer.firstname }
                                { elem.customer.lastname && ' ' + elem.customer.lastname }
                            </span>
                            <div
                                style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}
                            >
                                {
                                    elem.call && callAllowedStatus.includes(elem.status) && 
                                    <Link to={`/call/tickets/${elem.mongoId}?order=${true}`}>
                                        <div 
                                            style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0px" y="0px"><g data-name="Layer 23"><path d="M25.08594,30a3.07625,3.07625,0,0,1-.36621-.02148A26.417,26.417,0,0,1,9.75342,23.21l-.936-.93652A26.43954,26.43954,0,0,1,2.022,7.28125,3.16134,3.16134,0,0,1,4.0249,3.96973l4.666-1.81152a2.34771,2.34771,0,0,1,2.90674,1.06641L14.87793,9.248a2.3572,2.3572,0,0,1-.4165,2.79395l-2.5752,2.52832a29.62931,29.62931,0,0,0,2.57568,3.14551,26.60062,26.60062,0,0,0,2.96582,2.40234L19.958,17.54a2.35255,2.35255,0,0,1,2.7915-.418l6.02686,3.28125a2.34994,2.34994,0,0,1,1.06592,2.9043l-1.8125,4.66895A3.17841,3.17841,0,0,1,25.08594,30ZM11.1582,21.78613a24.41125,24.41125,0,0,0,13.79395,6.20605,1.18232,1.18232,0,0,0,1.21289-.73828l1.8125-4.66895a.34841.34841,0,0,0-.15674-.4248l-6.02539-3.28125a.34977.34977,0,0,0-.41016.0625L18.4458,21.93555a1.30073,1.30073,0,0,1-1.6543.17285,29.22265,29.22265,0,0,1-3.74316-2.97852,31.855,31.855,0,0,1-3.146-3.90918,1.30372,1.30372,0,0,1,.16406-1.667l2.99365-2.93945a.34911.34911,0,0,0,.06055-.41016L9.84082,4.18066a.34965.34965,0,0,0-.4248-.1582L4.748,5.835A1.161,1.161,0,0,0,4.0083,7.04883,24.41022,24.41022,0,0,0,10.25,20.87793Zm.39551-7.69336.00146.002Z"/></g></svg>
                                        </div>
                                    </Link>
                                }
                                {
                                    elem.sms && callAllowedStatus.includes(elem.status) &&
                                    <Link to={`/call/sms/send/${elem.mongoId}?order=${true}`}>
                                        <div 
                                            style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1" viewBox="0 0 490 490">
                                                <g>
                                                    <g>
                                                        <path d="M410.103,458.74L281.861,353.511H105.453C47.313,353.511,0,305.166,0,245.755V139.016    C0,79.606,47.313,31.26,105.453,31.26h279.094C442.687,31.26,490,79.606,490,139.016v106.739    c0,49.99-33.511,92.697-79.897,104.511V458.74z M105.453,61.886c-41.257,0-74.828,34.603-74.828,77.131v106.739    c0,42.528,33.571,77.131,74.828,77.131h187.354l86.671,71.104v-70.043l13.518-1.6c37.848-4.456,66.379-37.384,66.379-76.592    V139.016c0-42.528-33.571-77.131-74.828-77.131H105.453z"/>
                                                    </g>
                                                    <g>
                                                        <path d="M87.284,210.524c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8c0-4.89-2.931-7.223-14.041-10.931    c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208c11.125,0,21.085,2.527,26.931,5.458l-5.264,20.486    c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806c0,4.486,3.708,6.834,15.417,10.931    c18.139,6.236,25.75,15.417,25.945,29.459c0,17.75-14.042,30.819-41.362,30.819c-12.486,0-23.612-2.722-30.819-6.624    L87.284,210.524z"/>
                                                        <path d="M172.131,171.496c0-11.888-0.389-22.042-0.778-30.431h24.973l1.361,12.875h0.583c4.097-6.041,12.486-15.013,28.875-15.013    c12.292,0,22.042,6.236,26.139,16.18h0.389c3.514-4.875,7.806-8.778,12.292-11.499c5.279-3.125,11.125-4.68,18.154-4.68    c18.333,0,32.195,12.875,32.195,41.347v56.196h-28.875V184.58c0-13.862-4.501-21.862-14.056-21.862    c-6.819,0-11.709,4.68-13.653,10.348c-0.778,2.138-1.166,5.264-1.166,7.611v55.792h-28.875V183.01    c0-12.098-4.292-20.292-13.668-20.292c-7.596,0-12.098,5.862-13.847,10.737c-0.972,2.333-1.166,5.069-1.166,7.417v55.598h-28.875    V171.496z"/>
                                                        <path d="M339.686,210.524c5.458,3.32,16.778,7.223,25.556,7.223c8.972,0,12.681-3.125,12.681-8c0-4.89-2.931-7.223-14.042-10.931    c-19.709-6.639-27.32-17.361-27.126-28.681c0-17.75,15.223-31.208,38.82-31.208c11.125,0,21.07,2.527,26.931,5.458l-5.264,20.486    c-4.292-2.348-12.486-5.473-20.681-5.473c-7.223,0-11.32,2.931-11.32,7.806c0,4.486,3.708,6.834,15.417,10.931    c18.139,6.236,25.75,15.417,25.945,29.459c0,17.75-14.041,30.819-41.362,30.819c-12.486,0-23.612-2.722-30.819-6.624    L339.686,210.524z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </Link>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Address:</div>
                        <div className="orderList__item__value">
                            <button 
                                onClick={() => this.handleClick(elem)}
                                className='btn-sm btn btn-secondary'
                            >
                                Get Directions
                            </button>
                            {
                                elem.shippingAddress1 &&
                                <p style={{ marginBottom: 0 }}>
                                    <span id='holdtext'>{elem.shippingAddress1}</span>
                                    <button onClick={() => this.copyText(elem.shippingAddress1)} className='btn-sm btn mr-1'>
                                        <i className="fa fa-copy fa-lg mr-1"></i>
                                    </button>
                                </p>
                            }
                            {
                                !!elem.address &&
                                <>
                                    {
                                        elem.address.state &&
                                        <>State: {elem.address.state}<br/></>
                                    }
                                    {
                                        elem.address.apartment &&
                                        <>Apartment: {elem.address.apartment}<br/></>
                                    }
                                    {
                                        elem.address.entrance &&
                                        <>Entrance: {elem.address.entrance}<br/></>
                                    }
                                    {
                                        elem.address.intercom &&
                                        <>Intercom: {elem.address.intercom}</>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Pay:</div>
                        <div className="orderList__item__value">
                            <Dropdown
                                _changeOrderGroupListsDone = {this._changeOrderPaymentMethod}
                                list={this.props.paymentMethods} 
                                elem = { { 
                                    mongoId: elem.mongoId,
                                    label: elem.paymentMethod,
                                    status: elem.paymentMethod
                                } } 
                            />
                            {elem.paymentMethod == 'creditCard' && elem.paymentCustomField}
                        </div>
                    </div>
                    <div className="orderList__item">
                        <div className="orderList__item__type">Date:</div>
                        <div className="orderList__item__value">
                            {getFormattedDateTimeWithTZ(elem.dateAdded)}
                            <br/>
                            {
                                !!elem.dateAdvance &&
                                <span>Order in Advance {getFormattedDateTimeWithTZ(elem.dateAdvance)}</span>
                            }
                        </div>
                    </div>
                    <div className="orderList__item">
                        {
                            !this.props.filterTab &&
                            <Link 
                                style={!access.update ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                to={`/sale/order/update/${elem.mongoId}`}
                            >
                                <Badge 
                                    color="m-1 white-space btn btn-success btn-sm"
                                    className="mr-2"
                                    style={!access.update ? { pointerEvents: 'none', opacity: '.5'} : {}}
                                >
                                    <i className="fa fa-edit fa-lg m-1  "> </i> Edit
                                </Badge>
                            </Link>
                        }
                        {
                            this.props.filterTab 
                            ?
                            <>
                                <Badge 
                                    style={!access.archive_and_recover ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                    color="m-1 white-space btn btn-warning btn-sm"
                                    onClick={() => this.recover([elem.orderId])  }
                                    className="mr-2"
                                    >
                                    <i className="fa fa-undo fa-lg m-1 "> </i>
                                    Recover
                                </Badge>
                                <Badge 
                                    style={!access.delete ? { pointerEvents: 'none', opacity: '.5'} : {}} 
                                    color="m-1 white-space btn btn-danger btn-sm"
                                    onClick={() => this.destroy([elem.orderId])  }
                                    >
                                    <i className="fa fa-trash-o fa-lg m-1 "> </i>
                                    Delete
                                </Badge>
                            </>
                            :
                            <Badge style={!access.archive_and_recover ? { pointerEvents: 'none', opacity: '.5'} : {}} color="m-1 white-space btn btn-primary btn-sm"
                                    onClick={() => this.archive([elem.orderId])  }
                                    >
                                <i className="fa fa-file-archive-o fa-lg m-1"></i>
                                Archive
                            </Badge>
                        }
                        <Button 
                            color="primary"
                            onClick={() => this._toggle(elem)}
                            style={{marginLeft: "auto"}}
                        >
                            {
                                collapse ?
                                <i className="fa fa-angle-up fa-lg"></i>
                                :
                                <i className="fa fa-angle-down fa-lg"></i>
                            }
                        </Button>
                    </div>
                </section> 
                }

                {/*  ORDER LIST IS MOBILE VERSION */}

                <Card>
                    <Collapse isOpen={this.state.collapse}>
                        <CardBody className={'flex-row d-flex flex-wrap'}>
                            {
                                elem.loading &&
                                <span>{'loading...'}</span>
                            }
                            {elem.orderProduct.map((product, i) => 
                                <div key={i} className="col-12 col-md-3 col-lg-3 col-sm-4 col-xl-2 mb-3">
                                    <a target="_blank" href={elem.store && `${elem.store.ssl ? 'https://' + elem.store.url : "http://" + elem.store.url}/product/${product.product.productId}`}>
                                        <CardHeader className='flex-row d-flex flex-wrap h-100'>
   
                                            <Col className="col-12" style={{minHeight: "200px"}}>
                                                <img 
                                                    src={product.product.imageMain[0] ? config.MEDIA + 'image/d/350/' + product.product.imageMain[0].image.url : ''} 
                                                    className={'img'} alt="order img"
                                                />
                                            </Col>

                                            <Col className="col-12">
                                                { product.product.descriptions[0].name }
                                                {
                                                    product.discount &&
                                                    <div>
                                                        <strong>{'discount'}: </strong>
                                                        <span> 
                                                            
                                                            <span>
                                                                {product.discount.type}
                                                            </span>
                                                                
                                                        </span>
                                                    </div>
                                                }
                                                <div>
                                                    <strong>{'count'}: </strong>
                                                    <span> 
                                                        
                                                        <span>
                                                            {
                                                                Number(product.quantity)
                                                            }
                                                        </span>
                                                              
                                                    </span>
                                                </div>
                                                <div>
                                                    <strong>{'price'}: </strong>
                                                    <span> 
                                                        
                                                        <span>
                                                            {
                                                               "$" + Number(product.price).toFixed(1)
                                                            }
                                                        </span>
                                                              
                                                    </span>
                                                </div>
                                                
                                                {
                                                    parseInt(product.price) !== parseInt(product.product.price) &&
                                                    <div>
                                                        <strong>{'previous price'}: </strong>
                                                        <span> 
                                                            
                                                            <span>
                                                                {
                                                                    "$" + Number(product.product.price).toFixed(1)
                                                                }
                                                            </span>
                                                                    
                                                        </span>
                                                    </div>
                                                }
                                                
                                                {/* <div>
                                                    <strong>{'tax'}: </strong>
                                                    <span>

                                                        {
                                                            '$' + Number(product.tax).toFixed(1)
                                                        }

                                                   </span>
                                                </div> */}
                                                {
                                                    product.orderOption.map((el, j) =>
                                                        <div key={j}>
                                                            { 
                                                                !!(el.option && el.option.source) && 
                                                                [
                                                                    <strong>{el.option.source}</strong>,
                                                                     <br/>
                                                                ]
                                                            }
                                                            <strong>{el.name}: </strong>
                                                            {
                                                                !!parseFloat(el.value) &&
                                                                <span>{'$' +  Number(el.value)} </span>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                
                                            </Col>
                                        </CardHeader>
                                    </a>
                                </div>
                            )}

                        </CardBody>
                    </Collapse>
                </Card>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        permissions: state.account.userGroup.permissions,
        franchiseSelect: state.franchises.pagNotRemovedFranchise
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        archiveOrder: (token, ids) => dispatch(orderActions.archiveOrder(token, ids)),
        recoverOrder: (token, ids) => dispatch(orderActions.recoverOrder(token, ids)),
        listOrderProducts: (id) => dispatch(orderActions.listOrderProducts(id)),
        destroyOrder: (token, ids) => dispatch(orderActions.destroyOrder(token, ids)),
        changeStatus: (id, data, jwt, statusTab) => dispatch(orderActions.changeStatus(id, data, jwt, statusTab)),
        chooseStaff: (jwt, data) => dispatch(orderActions.chooseStaff(jwt, data)),
        changeFranchise: (id, data, jwt, statusTab) => dispatch(orderActions.changeFranchise(id, data, jwt, statusTab)),
        changePaymentMethod: (id, data, jwt, statusTab) => dispatch(orderActions.changePaymentMethod(id, data, jwt, statusTab)),
        showDialog: (id, status) => showCancelOrderDialog(dispatch, { id: id, status: status }),
        showOrderStaffDialog: (id, status) => showStaffDialog(dispatch, { id: id, status: status }),
        orderStatistics: (jwt, orderStatus) => dispatch(orderActions.statistics(jwt, orderStatus)),
        pageOrders: (jwt, page, count, status) => dispatch(orderActions.getPaginationPg(jwt, page, count, status))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
