import { USER_TYPES } from '../../types'
import UserGroupFields from '../../../system/Users/type'

const initialState = {
  data: [],
  navItem: new UserGroupFields().navBar,
  oneData: {},
  error: [],
  count: 0,
  pageNumber: {
    removed: localStorage.getItem('removedUserPage') || 1,
    notRemoved: localStorage.getItem('notRemovedUserPage') || 1
  },
  showEntries: {
    removed: localStorage.getItem('removedUserPageSize') || 10,
    notRemoved: localStorage.getItem('notRemovedUserPageSize') || 10
  },
  langTab : {
    userGroupId: 1,
    langTab: 0
  },
  filterTab: {
    filterTab: true,
    path: 'r/',
    removed: 'notRemoved'
  },
  dataFilter: {
    name: '',
    nameF: false,
    code: false,
    loacle: false,
    userGroupId: false,
    sortOrder: false
  },
  tabPanel: '1',
  checked: false,
  ids: [],
  usersSelect: [],
  selectedUsersCount: 0
};



const users = ( state = initialState, action = {}) => {

  switch (action.type) {

    case USER_TYPES.USER_PAG_F: {

        return {
          ...state,
          data: action.payload.data.map((elem) => state.ids.includes(elem.userId) ? ({ ...elem, isChecked: true }) : ({ ...elem, isChecked: false })),
          checked: action.payload.data.length && action.payload.data.every(elem => state.ids.includes(elem.userId)),
          count: action.payload.count
        }
    }

    case USER_TYPES.USER_SIZE_CHANGE: {

      const showEntries = Object.assign(state.showEntries, action.payload)
      
      return {
          ...state,
          showEntries: showEntries
        }
    }

    case USER_TYPES.USER_PAGE_CHANGE: {
      
       const pageNumber = Object.assign(state.pageNumber, action.payload)

        return {
            ...state,
            pageNumber: pageNumber
        }
    }

    case USER_TYPES.USER_FILETR_TABE_CHANGE: {
      
      return {
          ...state,
          filterTab: action.payload,
          checked: false
        }
    }

    case USER_TYPES.USER_TAB_PANEL_CHANGE: {
      
      return {
          ...state,
          tabPanel: action.payload
        }
    }

    case USER_TYPES.USER_CHECKBOX_CHANGE: {
      let ids = [...state.ids]
      const { checked, multiple, key } = action.payload
      const data = state.data.map(elem => {

            if (multiple) {

              elem['isChecked'] = checked
              return elem

            } 
            
            else {

                state.data[key]['isChecked'] = checked
                return elem
                
            }

        })

        data.forEach(elem => { if (elem.isChecked)  ids.push(elem.userId) })

      return {
          ...state,
          data: data,
          checked: !multiple ? data.every(element => element.isChecked): checked,
          ids: ids
      }
    }

    case USER_TYPES.USER_BULK_ARCIVE_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case USER_TYPES.USER_BULK_DESTROY_F: {
      
      return { 
          ...state,
          checked: false
      }

    }

    case USER_TYPES.USER_LANGUAGE_PANEL_CHANGE: {
      return { 
          ...state,
          langTab: action.payload
      }
    }


    case USER_TYPES.USER_CREATE_R: {
      return { 
          ...state,
          error: action.payload.response
      }
    }

    case USER_TYPES.USER_GET_ONE_F: {
      if (action.payload[0]) {

        if (action.payload[0].group) {
          action.payload[0].selectedData = {}
          action.payload[0].selectedData['user_group_id'] = { 
            label: action.payload[0].group.name, 
            value:action.payload[0].group.userGroupId 
          }
        }

        if (!action.payload[0].workingHours) {
          action.payload[0].workingHours = new UserGroupFields().workingHours
        }

      }
      
      return { 
          ...state,
          oneData: action.payload[0],
          navItem: new UserGroupFields().navBar,
          error: []
      }
    }

    case USER_TYPES.USER_REQUIRED_VALIDATION_R: {
      state.navItem = new UserGroupFields().navBar
      const tabPanel  = !action.payload.tab ? state.tabPanel : action.payload.errorMsg.some(element => element.tab === state.tabPanel) ? state.tabPanel : action.payload.errorMsg[0].tab
      action.payload.errorMsg.forEach(elem => {
          state.navItem[parseInt(elem.tab) - 1].count++
          state.navItem[parseInt(elem.tab) - 1].color = 'red'
          state.navItem[parseInt(elem.tab) - 1].inputs.map(val => {
             if (val.field === elem.name) {
               val['color'] = 'red'
             }
          })
      })
      return { 
          ...state,
          error: action.payload.errorMsg,
          tabPanel: tabPanel
      }
    }

    case USER_TYPES.USER_REQUIRED_VALIDATION_F: {
      return { 
          ...state,
          error: [],
          navItem: new UserGroupFields().navBar
      }
    }

    case USER_TYPES.NEW_USER: {
      return { 
          ...state,
          oneData: new UserGroupFields(),
          navItem: new UserGroupFields().navBar,
          error: []
      }
    }

    case USER_TYPES.FETCH_USER_SEE_ALL_F: {

      const newUsers = []
      action.payload.data.forEach(user => {
        newUsers.push({ 
          value: user.userId, 
          label: user.username
        })
      })

      let usersSelect = action.search ? newUsers : [...state.usersSelect, ...newUsers]
      usersSelect = usersSelect.filter((elem, index, self) =>
        index === self.findIndex((e) => (
          e.value === elem.value
        ))
      )

      return {
          ...state,
          usersSelect: usersSelect,
          selectedUsersCount: action.payload.count
      }

    }

    case USER_TYPES.USER_DATA_FILTER: {
      return { 
          ...state,
          // dataFilter: Object.assign({}, state.dataFilter, { [action.payload['key']]: action.payload['value'] }),
          dataFilter: { [action.payload['key']]: action.payload['value'] },
          error: []
      }
    }

    case 'CHANGE_DRIVER_STATE': {
      return {
        ...state,
        data: state.data.map((elem) => elem.userId == action.payload.userId ? { ...elem, ...action.payload } : elem)
      }
    }

    case USER_TYPES.USER_DELETE_F: {
      const id = action.payload;
      return {
          ...state,
          data: state.data.filter(item => item.userId !== id)
      }
    }
    
    default:
            return state;
  }
}
export {
    users
}