import { ORDER_TYPES } from '../types'
import storeActions from './store/storeActions';
import { Notification } from "element-react"
import { orderService } from "../services/orderService";
import config from '../../../config/app';
import axios from 'axios';

const getAllRemoved = (token) => {
  return dispatch => {
    return orderService.getAllRemoved(token).then(data => {
      return dispatch({
        type: ORDER_TYPES.GET_ALL_REMOVED_ORDER_LIST,
        payload : data
      })
    })
  }
};

const getAllDoneList = (token) => {
  return dispatch => {
    return orderService.getListDone(token).then(data => {
      return dispatch({
        type: ORDER_TYPES.GET_ALL_DONE_ORDER_LIST,
        payload : data
      })
    })
  }
};

const newOrder = (id) => {
  return dispatch => {
    return dispatch({
      type: ORDER_TYPES.GET_NEW_SOCKET_ORDER_LIST,
      payload: id
    })
  }
};

/**
 * @name getPagination
 * @returns {function(*)}
 */
const getPagination= (jwt, page, count, status) => {
  return dispatch => {
    return orderService.getPagination(jwt, page, count, status).then(orders => {
      return dispatch({
        type: ORDER_TYPES.GET_PAGINATION_ORDERS,
        payload : orders
      })
    })
  }
};


/**
 * @name getCategories
 * @returns {function(*)}
 */
const getCategories = (jwt, page, count) => {
  return dispatch => {
    return orderService.getCategories(jwt, page, count).then(orders => {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_CATEGORIES,
        payload : orders
      })
    })
  }
};

/**
 * @name getTypes
 * @returns {function(*)}
 */
const getTypes = (jwt, page, count) => {
  return dispatch => {
    return orderService.getTypes(jwt, page, count).then(orders => {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_TYPES,
        payload : orders
      })
    })
  }
};


const changeStatus = (id, data, jwt, filterTab) => {
  return dispatch => {
    return orderService.changeStatus(id, data, jwt).then(res=> {
      dispatch({
        type: ORDER_TYPES.CHANGE_STATUS_ORDERS,
        payload : {
          data: res, 
          filterTab
        } 
      })
      dispatch({
        type: 'DELIVERY_CHANGE_ORDER_STATUS',
        payload : {
          data: res, 
          filterTab
        } 
      })
    })
  }
}


const changeFranchise = (id, data, jwt) => {
  return dispatch => {
    return orderService.changeFranchise(id, data, jwt).then(_order=> {
      return dispatch({
        type: ORDER_TYPES.CHANGE_FRANCHISE_ORDERS,
        payload: data
      })
    })
  }
}


/**
 * @name changePaymentMethod
 * @returns {function(*)}
 */
const changePaymentMethod = (id, data, jwt) => {
  return dispatch => {
    return orderService.changePaymentMethod(id, data, jwt).then(_order=> {
      dispatch({
        type: ORDER_TYPES.CHANGE_ORDER_PAYMENT_METHOD,
        payload: data
      })
      dispatch({
        type: 'DELIVERY_CHANGE_ORDER_PAYMENT_METHOD',
        payload: data
      })
    })
  }
}

const getAllOrderStatus = (jwt) => {
  return dispatch => {
    return orderService.getAllOrderStatus(jwt).then(data=> {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_STATUS_LIST,
        payload: data
      })
    })
  }
}

const getAllOrderCancellationReasons = (jwt) => {
  return dispatch => {
    return orderService.getAllOrderCancellationReasons(jwt).then(data=> {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_CANCELLATION_REASONS_LIST,
        payload: data
      })
    })
  }
}

/**
 * @name getPaginationPg
 * @returns {function(*)}
 */
const getPaginationPg = (jwt, page, count, filter) => {
  return dispatch => {
    dispatch({
      type: ORDER_TYPES.GET_PAGINATION_ORDERS_PG_P,
    })
    orderService.getPaginationPg(jwt, page, count, filter)
    .then(orders => {
      dispatch({
        type: ORDER_TYPES.GET_PAGINATION_ORDERS_PG_F,
        payload : orders
      })
    })
    .catch(orders => {
      dispatch({
        type: ORDER_TYPES.GET_PAGINATION_ORDERS_PG_P,
        payload : orders
      })
    })
  }
};


/**
 * @name destroyOrder
 * @returns {function(*)}
 */
const destroyOrder = ( token, ids ) => {

  return async dispatch => {
    try {

        const data = await orderService.destroy(token, ids)

        Notification.success( {
          title: 'order',
          message: `${ids[0]} has been succesfuly deleted`
        }, 20 )

        dispatch( {
            type: ORDER_TYPES.DESTROY_ORDER,
            payload: ids
        } )

        return data

    } catch (err) {
        if (err.parent) {
          Notification.error( {
            title: err.name,
            message: `${err.parent.column}`
          }, 20) 
        }
      }
    }
  }


/**
 * @name archiveOrder
 * @returns {function(*)}
 */
const archiveOrder = ( token, ids ) => {

  return async dispatch => {
    try {

        const data = await orderService.archive(token , ids)

        Notification.success( {
          title: 'order',
          message: `${ids[0]} has been succesfuly archived `
        }, 20 )

        dispatch( {
            type: ORDER_TYPES.DESTROY_ORDER,
            payload: ids
        } )

        return data

    } catch (err) {
        if (err.parent) {
          Notification.error( {
            title: err.name,
            message: `${err.parent.column}`
          }, 20) 
        }
      }
    }
  }

/**
 * @name recoverOrder
 * @returns {function(*)}
*/
const recoverOrder = ( token, ids ) => {

  return async dispatch => {
    try {

        const data = await orderService.recover(token , ids)

        Notification.success( {
          title: 'order',
          message: `${ids[0]} has been succesfuly recovered`
        }, 20 )

        dispatch( {
            type: ORDER_TYPES.DESTROY_ORDER,
            payload: ids
        } )

        return data

    } catch (err) {
        if (err.parent) {
          Notification.error( {
            title: err.name,
            message: `${err.parent.column}`
          }, 20) 
        }
      }
    }
  }

  /**
 * @name getOrder
 * @returns {function(*)}
*/
const getOrder = ( token, id ) => {

  return async dispatch => {
    try {

      dispatch({
        type: ORDER_TYPES.GET_ORDER_P
      })

      const data = await orderService.getOrder(token , id)

      dispatch({
        type: ORDER_TYPES.GET_ORDER_F,
        payload: data
      })

      return data

    } catch (err) {

        dispatch({
          type: ORDER_TYPES.GET_ORDER_R,
          payload: err.response
        })

      }
    }
  }
  

  /**
 * @name statistics
 * @returns {function(*)}
 */
const statistics = (jwt, filter) => {
  return dispatch => {
    return orderService.statistics(jwt, filter)
    .then(data => {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_STATISTICS,
        payload: data
      })
    })
  }
};


  /**
 * @name getLocations
 * @returns {function(*)}
 */
const getLocations = (jwt, filter) => {
  return dispatch => {
    return orderService.getLocations(jwt, filter)
    .then(data => {
      return dispatch({
        type: ORDER_TYPES.GET_ORDER_LOCATIONS_F,
        payload: data
      })
    })
  }
};



const update = (jwt, body, history, discard) => {

  return async dispatch => {
    try {

      dispatch({
        type: 'ORDER_UPDATE_P'
      })

      await axios.put(
        config['ORDER'] + `s/sales/${body.orderId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
      })

      Notification.warning({
        title: 'Updated',
        message: 'Order succesfuly updated '
      }, 20)

      if (discard) {
        history.push(`${discard}`)
      } else {
        history.push('/sale/order')
      }

      dispatch({
        type: 'ORDER_UPDATE_F',
        payload: body
      })
        
    } catch (error) {

      if (error.response.status === 422) {
        error.response.data.forEach(err => {
          Notification.error({
            title: err.path,
            message: err.msg
          }, 20)
        })
      } 
      
      dispatch({
        type: 'ORDER_UPDATE_R',
        payload: error
      })

    }
  }
}

const create = (jwt, body, history, discard) => {
  return async dispatch => {
    dispatch({
      type: 'ORDER_CREATE_P'
    })
    await axios.post(
        config['ORDER'] + 's/sales',
        body,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`
          }
        }
      )
      .then(response => {

        Notification.success({
          title: 'Created',
          message: 'Order succesfuly created',
        }, 20)

        if (discard) {
          history.push(`${discard}`)
        } else {
          history.push('/sale/order')
        }

        dispatch({
          type: 'ORDER_CREATE_F',
          payload: response
        })

      })
      .catch(error => {

        if (error.response.status === 422) {
          error.response.data.forEach(err => {
            Notification.error({
              title: err.path,
              message: err.msg
            }, 20)
          })
        } 
        dispatch({
          type: 'ORDER_CREATE_R',
          payload: error
        })

      })
    }
  }

  const chooseStaff = (jwt, body) => {
    return async dispatch => {

      dispatch({
        type: 'ORDER_CHOOSE_STAFF_P'
      })

      await axios.post(
          config['ORDER'] + 's/sales/staff',
          body,
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${jwt}`
            }
          }
        )
        .then(response => {
  
          dispatch({
            type: 'ORDER_CHOOSE_STAFF_F',
            payload: response
          })
  
        })
        .catch(error => {
  
          if (error.response.status === 422) {
            error.response.data.forEach(err => {
              Notification.error({
                title: err.path,
                message: err.msg
              }, 20)
            })
          } 

          dispatch({
            type: 'ORDER_CHOOSE_STAFF_R',
            payload: error
          })

        })
      }
    }

  const setNewOrder = () => {
    return async (dispatch) => {
      dispatch({
        type: 'NEW_ORDER_F',
      })
    }
  }

  const uploadFile = (files) => {
    return async dispatch => {
        try {

          dispatch({
            type: ORDER_TYPES.UPLOAD_LICENSE_FILES_R
          })

          const formData = new FormData()

          for(let file of files) {
            formData.append('file', file)
          }
          
          const { data } = await axios.post(
            config['MEDIA'] + 'files/upload',
            formData,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.token}`
              }
            }
          )

          dispatch({
            type: ORDER_TYPES.UPLOAD_LICENSE_FILES_F,
            payload: data,
          })

          return data

        } catch (e) {

          dispatch({
            type: ORDER_TYPES.UPLOAD_LICENSE_FILES_R,
            payload: e
          })

          return e

        }
    }
}

/**
 * @name listOrderProducts
 * @returns {function(*)}
 */
const listOrderProducts = ( id ) => {

  return async dispatch => {
    try {

        dispatch( {
            type: ORDER_TYPES.ORDER_PRODUCT_LIST_P,
            payload: {
              id: id
            }
        } )

        const data = await orderService.listOrderProducts(id)

        dispatch( {
            type: ORDER_TYPES.ORDER_PRODUCT_LIST_F,
            payload: {
              data: data,
              id: id
            }
        } )

    } catch (err) {
        if (err.parent) {
          Notification.error( {
            title: err.name,
            message: `${err.parent.column}`
          }, 20) 
        }
        dispatch( {
          type: ORDER_TYPES.ORDER_PRODUCT_LIST_R,
          payload: {
            id: id
          }
        } )
      }
    }
  }





export {
  getAllRemoved, 
  getAllDoneList,
  newOrder, 
  getPagination, 
  changeStatus,
  changeFranchise,
  changePaymentMethod,
  getAllOrderStatus,
  getAllOrderCancellationReasons,
  getPaginationPg,
  destroyOrder,
  archiveOrder,
  recoverOrder,
  getOrder,
  statistics,
  getLocations,
  update,
  create,
  setNewOrder,
  uploadFile,
  getCategories,
  getTypes,
  chooseStaff,
  listOrderProducts
}
